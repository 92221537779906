import { FC, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import { asNumber } from "@taxscribe/utils";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import icon from "../../../images/view-file-icon.svg";
import { formatDate, trimString } from "../../../utils";
import { validateCheckmark } from "../../BulkActions/validations";
import useFormNameProcess from "../../../hooks/useProcessFormName";
import { Account, formWithJobRelated } from "../../../utils/Interfaces";
import useJurisdictionContext from "../../../hooks/useJurisdictionContext";

const StyledCheckBox = styled.input.attrs({ type: "checkbox" })`
  height: 1.25em;
  width: 1.25em;
  border: 0.125rem solid rgba(0, 0, 0, 0.25);
`;

const SpecialBreakWordContainer = styled.h6`
  word-break: break-word;
`;

const ChatWrapper = styled.div<{ unreadCount: number | undefined }>`
  ${({ unreadCount }) => {
    if (unreadCount) {
      return `
        color: #0dcaf0;
      `;
    }
  }}
`;

interface CustomTableRowComponent {
  result: Account;
  checkedForms: Array<Account>;
  setCheckedForms: Function;
  formsWaitingUpdate: Array<formWithJobRelated>;
  bulkActionSelected: string | null;
}

const FormTableRow: FC<CustomTableRowComponent> = ({
  result,
  checkedForms,
  setCheckedForms,
  formsWaitingUpdate,
  bulkActionSelected,
}) => {
  const { jurisdiction } = useJurisdictionContext();
  const formNameProcess = useFormNameProcess();

  const formatStatusClassName = useCallback(
    (status: string) => status.toLowerCase().replace(" ", "-"),
    [],
  );

  const handleFormSelection = useCallback(
    (result: Account) => {
      const optionAlreadyAdded = checkedForms.some(
        (form: Account) => form.formId === result.formId,
      );
      const prevCheckedForms = [...checkedForms];
      if (optionAlreadyAdded) {
        const deleteForm = prevCheckedForms.filter(
          (form: Account) => form.formId !== result.formId,
        );
        setCheckedForms(deleteForm);
      } else {
        const addForm = [...prevCheckedForms, result];
        setCheckedForms(addForm);
      }
    },
    [checkedForms, setCheckedForms],
  );

  const {
    accountNumberPrimary,
    businessName,
    businessAddress,
    businessCity,
    jurisdictionName,
    status,
    statusChangedAt,
    taxYear,
    formId,
    assignedToName,
    formType,
    syncStatus,
    unreadCount,
  } = result;

  const { isDisabled, content } = useMemo(
    () =>
      validateCheckmark({
        stateAbbr: jurisdiction.stateAbbr,
        formId,
        formType,
        status,
        formsWaitingUpdate,
        bulkActionSelected,
        accountNum: accountNumberPrimary ?
          trimString(accountNumberPrimary) :
          "",
        assignedToName: assignedToName ? trimString(assignedToName) : "",
        syncStatus,
        formNameProcess,
      }),
    [
      accountNumberPrimary,
      assignedToName,
      bulkActionSelected,
      formId,
      formNameProcess,
      formType,
      formsWaitingUpdate,
      status,
      syncStatus,
      jurisdiction,
    ],
  );

  const formName = formNameProcess(formType);

  return (
    <ListGroup
      variant="flush"
      className="border-top-0"
    >
      <ListGroup.Item>
        <Row className="p-3 d-flex justify-content-between align-items-center listing-row">
          <Col
            md={5}
            xl={6}
          >
            <Row className="gx-2">
              <Col
                xl={1}
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip-3">{content}</Tooltip>}
                >
                  <span className="d-inline-block">
                    <StyledCheckBox
                      className="form-check-input"
                      aria-label="Select"
                      disabled={isDisabled}
                      checked={checkedForms.some(
                        (form: Account) => form.formId === formId,
                      )}
                      onChange={() => handleFormSelection(result)}
                    />
                  </span>
                </OverlayTrigger>
              </Col>
              <Col
                xl={3}
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start"
              >
                <p className="fw-bold mb-xl-0 text-break">
                  {accountNumberPrimary?.trim() || "N/A"}
                </p>
              </Col>
              <Col
                xl={4}
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start"
              >
                <h6 className="mb-lg-0 text-capitalize fw-normal">
                  {businessName}
                </h6>
              </Col>
              <Col
                xl={4}
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start"
              >
                <p className="text-capitalize fw-light mb-lg-0">
                  <small>
                    {businessAddress}
                    <br />
                    {businessCity}, {jurisdictionName}
                  </small>
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            md={7}
            xl={6}
          >
            <Row>
              <Col
                lg={3}
                className="d-flex justify-content-lg-center align-self-center
                      flex-column flex-xl-row text-center text-xl-end align-items-center"
              >
                <SpecialBreakWordContainer className="mb-lg-0 text-capitalize">
                  {assignedToName || "Unassigned"}
                </SpecialBreakWordContainer>
              </Col>
              <Col
                lg={1}
                className="d-flex justify-content-lg-end align-self-center
                      flex-column flex-xl-row text-center text-xl-end align-items-center pe-lg-3"
              >
                <h6 className="mb-lg-0 fw-semibold">{taxYear}</h6>
              </Col>
              <Col
                lg={4}
                className="d-flex justify-content-lg-center align-items-center
                      flex-column flex-xl-row text-center"
              >
                <h6
                  className={`${formatStatusClassName(
                    status,
                  )} text-uppercase mb-lg-0 me-lg-1 p-3`}
                >
                  {status === "rejected" ? "returned" : status}
                </h6>
                {syncStatus === "accepted" ? (
                  <h6 className="synced text-uppercase mb-lg-0 p-3">
                    Synced
                  </h6>
                ) : null}
                {syncStatus === "exported" ? (
                  <h6 className="synced text-uppercase mb-lg-0 p-3">
                    Exported
                  </h6>
                ) : null}
              </Col>
              <Col
                lg={2}
                className="d-flex justify-content-lg-center align-items-center
                      flex-column flex-xl-row text-center"
              >
                <h6 className={"mb-lg-0 py-3 px-4"}>
                  {formatDate(statusChangedAt)}
                </h6>
              </Col>
              <Col
                lg={1}
                className="d-flex justify-content-lg-center align-items-center
                      flex-column flex-xl-row text-center fw-bold"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">Unread messages</Tooltip>
                  }
                >
                  <ChatWrapper unreadCount={asNumber(unreadCount)}>
                    <FontAwesomeIcon
                      icon={faComments}
                      size="xl"
                    />
                    {unreadCount}
                  </ChatWrapper>
                </OverlayTrigger>
              </Col>
              <Col
                lg={1}
                className="d-flex justify-content-lg-center align-items-center
                      flex-column flex-xl-row text-center"
              >
                <Link to={`form/${formId}`}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">View {formName}</Tooltip>
                    }
                  >
                    <span>
                      <img
                        alt="view-listing-logo"
                        src={icon}
                        width="26"
                        height="32"
                      />{" "}
                    </span>
                  </OverlayTrigger>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default FormTableRow;
