import { ReactNode } from "react";

// TODO: Refactor this file.
export const invalidAccountNumberMsg = "Account number not available";
const formNotAssigned = "Form not assigned";
const invalidStatusMsg = "Invalid form status";
const alreadySyncedMsg = "Form already synced";
const invalidAssignMsg = "No user assigned to the form";
const invalidStatus = ["Approved", "Inadmissible", "Returned"];

// Helpers
const dynamicInvalidContent = (messages: Array<string>) => {
  return messages.map((msg: string) => (
    <>
      - {msg} <br />
    </>
  ));
};

const getValidFormMsg = (formType: string, formNameProcess: Function) =>
  `Select ${formNameProcess(formType)} for bulk processing`;

const getWaitingBulkUpdateMsg = (jobId: number) =>
  `Form is in queue to be updated by job (${jobId})`;

const getInvalidButtonsDecisions = (
  invalidAccoutNumber: boolean,
  invalidAssign: boolean,
  invalidFormStatus: boolean,
) => {
  const possibleInvalids = [
    { msg: invalidAccountNumberMsg, validation: invalidAccoutNumber },
    { msg: invalidAssignMsg, validation: invalidAssign },
    { msg: invalidStatusMsg, validation: invalidFormStatus },
  ];
  return possibleInvalids
    .filter(({ validation }) => validation)
    .map(({ msg }) => msg);
};

// Validations
const searchFormUpdateJob = (
  currentFormId: number,
  formsWaitingUpdate: Array<any>,
) => {
  const formFound = formsWaitingUpdate.find(
    ({ formId }) => currentFormId === formId,
  );
  return formFound ? formFound.jobId : false;
};

const validateAssignBulk = (status: string) => {
  if (invalidStatus.includes(status)) {
    return {
      isDisabled: true,
      content: invalidStatusMsg,
    };
  }

  return null;
};

const validateApprove = (status: string, accountNum?: string) => {
  const invalidAccoutNumber = !accountNum || accountNum?.length === 0 || accountNum === "N/A";

  if (invalidAccoutNumber && invalidStatus.includes(status)) {
    const bothInvalid = dynamicInvalidContent([
      invalidAccountNumberMsg,
      invalidStatusMsg,
    ]);
    return {
      isDisabled: true,
      content: bothInvalid,
    };
  }

  if (invalidStatus.includes(status)) {
    return {
      isDisabled: true,
      content: invalidStatusMsg,
    };
  }

  // NOTE: This is order dependent! When I get around to reworking all this
  // logic someday in the future I will ensure that it is much less brittle
  // and complected, but until then...
  if (invalidAccoutNumber) {
    return {
      isDisabled: true,
      content: invalidAccountNumberMsg,
    };
  }

  return null;
};

const validateSync = ({
  stateAbbr,
  status,
  accountNum,
  assignedToName,
  syncStatus,
  formType,
}: {
  stateAbbr: string,
  status: string,
  accountNum: string,
  assignedToName: string | null,
  syncStatus: string,
  formType?: string,
}) => {
  if (stateAbbr.toUpperCase() !== "NC") {
    return {
      isDisabled: true,
      isHidden: true,
    };
  }

  // Slowly migrating off of this janky logic...
  if (formType === "IPP Listing" || formType === "Listing") {
    return {
      isDisabled: true,
      isHidden: true,
    };
  }
  const invalidAccoutNumber = accountNum.length === 0 || accountNum === "N/A";

  if (invalidAccoutNumber && status !== "Approved") {
    const bothInvalid = dynamicInvalidContent([
      invalidAccountNumberMsg,
      invalidStatusMsg,
    ]);
    return {
      isDisabled: true,
      content: bothInvalid,
    };
  }

  if (invalidAccoutNumber) {
    return {
      isDisabled: true,
      content: invalidAccountNumberMsg,
    };
  }

  if (status !== "Approved") {
    return {
      isDisabled: true,
      content: invalidStatusMsg,
    };
  }

  if (syncStatus === "accepted") {
    return {
      isDisabled: true,
      content: alreadySyncedMsg,
    };
  }

  if (!assignedToName) {
    return {
      isDisabled: true,
      content: formNotAssigned,
    };
  }

  return null;
};

// TODO: Unwravel this and related spaghetti code.
const validateDecision = (
  status: string,
  accountNum: string,
  assignedToName: string | null,
) => {
  const invalidAccoutNumber = !accountNum || accountNum.length === 0 || accountNum === "N/A";
  const invalidAssign = !assignedToName;
  const invalidMsgs = getInvalidButtonsDecisions(
    invalidAccoutNumber,
    invalidAssign,
    invalidStatus.includes(status),
  );

  if (invalidMsgs.length > 1) {
    return {
      isDisabled: true,
      content: dynamicInvalidContent(invalidMsgs),
    };
  }

  if (invalidAccoutNumber) {
    return {
      isDisabled: true,
      content: invalidAccountNumberMsg,
    };
  }

  if (invalidStatus.includes(status)) {
    return {
      isDisabled: true,
      content: invalidStatusMsg,
    };
  }

  if (invalidAssign) {
    return {
      isDisabled: true,
      content: invalidAssignMsg,
    };
  }

  return null;
};

interface ValidateCheckmarkArgs {
  formId: number;
  formType: string;
  status: string;
  accountNum: string;
  assignedToName: string | null;
  formsWaitingUpdate: Array<any>;
  bulkActionSelected: string | null;
  syncStatus: string;
  formNameProcess: Function;
  stateAbbr: string;
}

// Main handlers
export const validateCheckmark = ({
  formId,
  formType,
  status,
  accountNum,
  assignedToName,
  formsWaitingUpdate,
  bulkActionSelected,
  syncStatus,
  formNameProcess,
  stateAbbr,
}: ValidateCheckmarkArgs) => {
  if (!bulkActionSelected) {
    return {
      isDisabled: true,
      content: "No bulk action selected",
    };
  }

  const jobRelatedToForm = searchFormUpdateJob(formId, formsWaitingUpdate);
  if (jobRelatedToForm) {
    return {
      isDisabled: true,
      content: getWaitingBulkUpdateMsg(jobRelatedToForm),
    };
  }

  if (bulkActionSelected === "assign") {
    const assignContent = validateAssignBulk(status);
    if (assignContent) return assignContent;
  }

  if (bulkActionSelected === "approve") {
    const approveContent = validateApprove(status, accountNum);
    if (approveContent) return approveContent;
  }

  if (bulkActionSelected === "sync") {
    const syncContent = validateSync({
      stateAbbr,
      status,
      accountNum,
      assignedToName,
      syncStatus,
    });
    if (syncContent) return syncContent;
  }

  return {
    isDisabled: false,
    content: getValidFormMsg(formType, formNameProcess),
  };
};

// This whole thing is a very hacky solution that needs to be rewritten from
// the ground up.
export const validateDecisionButton = ({
  action,
  status,
  stateAbbr,
  accountNum,
  assignedToName,
  syncStatus,
  formType,
}: {
  action: string;
  status: string;
  stateAbbr: string;
  accountNum: string;
  assignedToName: string | null;
  syncStatus: string;
  formType?: string;
}): { isDisabled?: boolean, isHidden?: boolean, content?: ReactNode } => {
  if (action === "decision") {
    const approveContent = validateDecision(status, accountNum, assignedToName);
    if (approveContent) return approveContent;
  }

  if (action === "sync") {
    const syncContent = validateSync({
      stateAbbr,
      status,
      accountNum,
      assignedToName,
      syncStatus,
      formType,
    });
    if (syncContent) return syncContent;
  }

  return {
    isDisabled: false,
    content: null,
  };
};
