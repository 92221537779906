import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { startCase } from "lodash";

import SyntaxHighlighter from "../CustomPrismViewer";
import addCustomStyles, { possibleStyles } from "../CustomSyncStyles";
import { Col, Row } from "react-bootstrap";
import CustomDropdown from "../../../../CustomDropdown";
import { DropdownOptions } from "@taxscribe/ui/dist/components/SortDropdown/SortDropdown";
import { useHydration } from "@taxscribe/ui";
import styled from "styled-components";
import JSONSchemaErrors from "./JSONSchemaErrors";
import useSchemaErrors from "./useSchemaErrors";

const defaultStyle = "coyWithoutShadows";
const styleKeys = Object.keys(possibleStyles);
const styleOptions: DropdownOptions[] = styleKeys.map((key) => ({
  text: startCase(key),
  value: key,
  active: false,
}));

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  margin-bottom: 1rem;
`;

export interface NCPTSDataOverviewProps {
  shortName: string;
  ncptsData: null | Record<string, any>;
  syncData: null | Record<string, any>;
}

const CodeViewWrapper = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
`;

const NCPTSDataOverview: FC<NCPTSDataOverviewProps> = ({
  ncptsData,
  syncData,
  shortName,
}) => {
  const { data, persist } = useHydration<{ baseStyle?: string }>("debugStye");
  const [highlightPks] = useState(true);
  const [selectedBaseStyle, setSelectedBaseStyle] = useState<
    keyof typeof possibleStyles
  >(data?.baseStyle || defaultStyle);

  useEffect(() => {
    persist({ baseStyle: selectedBaseStyle });
  }, [persist, selectedBaseStyle]);

  const styleWithCustomTypes = useMemo(() => {
    const selectedStyle =
      possibleStyles[selectedBaseStyle] || possibleStyles[defaultStyle];
    return addCustomStyles(selectedStyle, highlightPks);
  }, [selectedBaseStyle, highlightPks]);

  const setStyle = useCallback((desiredStyle: string) => {
    setSelectedBaseStyle(desiredStyle as keyof typeof possibleStyles);
  }, [setSelectedBaseStyle]);

  const {
    jsonErrors: ncptsDataErrors,
  } = useSchemaErrors({
    data: ncptsData,
    shortName,
  });

  const {
    jsonErrors: syncDataErrors,
  } = useSchemaErrors({
    data: syncData,
    shortName,
  });

  if (!syncData || !ncptsData) return null;
  return (
    <>
      <Row>
        <Col>
          <HeaderWrapper>
            <CustomDropdown
              text="Select Style"
              id="style-selector"
              items={styleOptions}
              onSelect={setStyle}
            />
          </HeaderWrapper>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <h2>NCPTS Data</h2>
            <p>
              This is the data that was received from NCPTS when this form was
              created
            </p>
          </div>
          <CodeViewWrapper>
            <JSONSchemaErrors
              style={styleWithCustomTypes}
              dataErrors={ncptsDataErrors}
            />
            <SyntaxHighlighter
              language="custom-json"
              style={styleWithCustomTypes}
              showLineNumbers
            >
              {JSON.stringify(ncptsData, null, 2)}
            </SyntaxHighlighter>
          </CodeViewWrapper>
        </Col>
        <Col md={6}>
          <div>
            <h2>Sync Preview</h2>
            <p>
              This data is a preview of the payload that will be sent to NCPTS
            </p>
          </div>
          <CodeViewWrapper>
            <JSONSchemaErrors
              style={styleWithCustomTypes}
              dataErrors={syncDataErrors}
            />
            <SyntaxHighlighter
              language="custom-json"
              style={styleWithCustomTypes}
              showLineNumbers
            >
              {JSON.stringify(syncData, null, 2)}
            </SyntaxHighlighter>
          </CodeViewWrapper>
        </Col>
      </Row>
    </>
  );
};

export default NCPTSDataOverview;
