import React, { FC, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormDataUnion } from "@taxscribe/gql-queries";
import { Metadata, NCExtension, NCExtensionData } from "@taxscribe/form";

import { CurrentSubmission, FormPeriod } from "../queries/returnTypes";
import NCPTSDebugHelper from "../../../shared/NCPTSDebugHelper";
import ShowPDFOrForm from "../../../shared/ShowPDFOrForm";
import { useParams } from "react-router-dom";
import { useGetNcptsDataForJurisdiction } from "@taxscribe/ui";
import useJurisdictionContext from "../../../../hooks/useJurisdictionContext";

interface NCExtensionViewProps {
  formState: FormDataUnion;
  formPeriod: FormPeriod;
  source: string | undefined;
  metadata: Partial<CurrentSubmission> & Metadata;
}

const NCExtensionView: FC<NCExtensionViewProps> = ({
  source,
  metadata,
  formState,
  formPeriod,
}) => {
  const data = formState as NCExtensionData;
  const filingDeadline = new Date(
    formPeriod.filingDeadlineFormattedDay,
  ).toLocaleDateString();

  const metadataWithSource = useMemo(() => {
    return {
      ...metadata,
      source,
    };
  }, [metadata, source]);

  const { formId: stringFormId } = useParams();
  const { jurisdiction: { id: jurisdictionId } } = useJurisdictionContext();
  const getNcptsData = useGetNcptsDataForJurisdiction({
    jurisdictionId,
    formId: Number(stringFormId),
  });

  return (
    <ShowPDFOrForm formPeriod={formPeriod}>
      <NCExtension.Provider
        readOnly
        renderingApp="TSG"
        initialState={data}
        getNcptsData={getNcptsData}
        metadata={metadataWithSource}
      >
        <Container fluid>
          <Row>
            <Col sm={12}>
              <NCExtension.ExtensionForm filingDeadline={filingDeadline} />
            </Col>
          </Row>
        </Container>
        <NCPTSDebugHelper />
      </NCExtension.Provider>
    </ShowPDFOrForm>
  );
};

export default NCExtensionView;
