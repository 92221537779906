import React, { FC } from "react";
import styled from "styled-components";

import SyntaxHighlighter from "../CustomPrismViewer";
import { currentSchemaVersion } from "./useSchemaErrors";

export interface JSONSchemaErrorsProps {
  style: SyntaxHighlighter["props"]["style"];
  dataErrors: Record<string, any> | null | undefined;
}

// The important breaks my heart, but I have to use it here as the
// SyntaxHighlighter applies the style directly to the HTML tag, and I can't
// override it with styled-components.
const Wrapper = styled.div`
  border: 2px solid red;
  && pre {
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const JSONSchemaErrors: FC<JSONSchemaErrorsProps> = ({ style, dataErrors }) => {
  if (!dataErrors) {
    return null;
  }

  return (
    <div>
      <h3>
        The following errors were identified using Schema version B
        {currentSchemaVersion} provided by Farragut.
      </h3>
      <Wrapper>
        <SyntaxHighlighter
          language="custom-json"
          style={style}
        >
          {JSON.stringify(dataErrors, null, 2)}
        </SyntaxHighlighter>
      </Wrapper>
    </div>
  );
};

export default JSONSchemaErrors;
