import { gql, useLazyQuery } from "@apollo/client";

const GET_SYNC_DATA = gql`
  query SyncPreview($uuid: String, $jurisdictionId: Int) {
    jurisdiction(id: $jurisdictionId) {
      id
      form(uuid: $uuid) {
        id
        ddbKey
        syncPreview {
          preview
        }
      }
    }
  }
`;

export interface GetSyncDataReturn {
  jurisdiction: {
    id: number;
    form: {
      id: number;
      ddbKey: string;
      syncPreview: {
        preview: JSON;
      };
    };
  };
}

const useGetSyncData = () => {
  return useLazyQuery<
    GetSyncDataReturn,
    { uuid: string; jurisdictionId: number }
  >(GET_SYNC_DATA);
};

export default useGetSyncData;
