import React, { FC, useMemo } from "react";

import Rendition144 from "./Rendition144";
import NCListingView from "./NCListingView";
import MIListingView from "./MIListingView";
import NCExtensionView from "./NCExtensionView";
import { CurrentSubmission, FormPeriod } from "../queries/returnTypes";
import MIExemptionView from "./MIExemptionView";
import useJurisdictionForm from "../../../../hooks/useJurisdictionForm";
import GAHomesteadExemptionView from "./GAHomesteadExemptionView";
import { FormDataUnion } from "@taxscribe/gql-queries";
import PT50PView from "./PT50PView";
import LAT5View from "./LAT5View";
import { deepPurge } from "@taxscribe/utils";
import NCIPPListingView from "./NCIPPListing";
import { Metadata } from "@taxscribe/form";
import PT50PFView from "./PT50PFView";
import PT50AView from "./PT50AView";
import PT50MView from "./PT50MView";
import TXExtensionView from "./TXExtension";

export interface FormViewProps {
  formPeriod: FormPeriod;
  formState: FormDataUnion;
  source: string | undefined;
  metadata: Partial<CurrentSubmission> & Metadata;
  extensionId: number | null;
}

const formComponentMap: Record<string, FC<FormViewProps>> = {
  PT50PData: PT50PView,
  PT50AData: PT50AView,
  PT50MData: PT50MView,
  PT50PFData: PT50PFView,
  NCListingData: NCListingView,
  MIListingData: MIListingView,
  NCExtensionData: NCExtensionView,
  MIExemptionData: MIExemptionView,
  GAHomesteadExemptionData: GAHomesteadExemptionView,
  LAT5Data: LAT5View,
  NCIPPListingData: NCIPPListingView,
  Rendition144Data: Rendition144,
  TXExtensionData: TXExtensionView,
};

interface ViewFormProps {
  extensionId: number | null;
}

const ViewForm: FC<ViewFormProps> = ({ extensionId }) => {
  const { data } = useJurisdictionForm({});

  const form = data?.jurisdiction?.form;
  const metadata = useMemo(() => {
    const submission =
      form?.currentSubmission || ({} as Partial<CurrentSubmission>);
    return {
      ...submission,
      filingYear: String(form?.formPeriod?.filingYear || ""),
    } as Metadata & Partial<CurrentSubmission>;
  }, [form]);

  const filteredData = useMemo(() => {
    if (!data) return null;
    return deepPurge("__typename", data);
  }, [data]);

  if (!filteredData || !form) return null;

  const formPeriod = form.formPeriod;

  const formType = filteredData?.jurisdiction?.form?.data?.formType || "";
  const Comp = formComponentMap[formType];
  if (!Comp) {
    console.error("Unable to determine view for formType:", formType);
    return null;
  }

  return (
    <Comp
      metadata={metadata}
      source={form?.source}
      formPeriod={formPeriod}
      extensionId={extensionId}
      formState={filteredData.jurisdiction.form.data}
    />
  );
};

export default ViewForm;
