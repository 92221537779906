"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const bppSchema = {
    // $schema: "http://json-schema.org/draft-04/schema#",
    title: "BusinessListingAbstractMapper",
    type: "object",
    additionalProperties: false,
    properties: {
        OwnershipInformation: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingOwnershipInformationMapper",
                },
            ],
        },
        SitusInformation: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingSitusInformationMapperBase",
                },
            ],
        },
        AbstractInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingAbstractInformationMapper",
                },
            ],
        },
        ContactInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingContactInformationMapper",
                },
            ],
        },
        BusinessInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingBusinessInformationMapper",
                },
            ],
        },
        OutOfBusinessInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingOutOfBusinessInformationMapper",
                },
            ],
        },
        CountiesWhereBusinessIsListed: {
            type: ["array", "null"],
            items: {
                $ref: "#/definitions/ListingCountyInformationMapper",
            },
        },
        CountyValidValues: {
            type: ["array", "null"],
            items: {
                $ref: "#/definitions/ValidValueLookup",
            },
        },
        PropertyInformation: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingPropertyInformationMapper",
                },
            ],
        },
    },
    definitions: {
        ListingOwnershipInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                Owners: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingOwnerMapper",
                    },
                },
                OwnerValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/OwnershipLookup",
                        },
                    ],
                },
            },
        },
        ListingOwnerMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                OwnerPk: {
                    type: ["null", "string"],
                },
                COACode: {
                    type: ["null", "string"],
                },
                OwnerOrder: {
                    type: ["null", "string"],
                },
                OwnerOrderNCPTSCode: {
                    type: ["null", "string"],
                },
                OwnershipType: {
                    type: ["null", "string"],
                },
                OwnershipTypeNCPTSCode: {
                    type: ["null", "string"],
                },
                PercentOwnership: {
                    type: ["null", "string"],
                },
                SendMail: {
                    type: ["null", "string"],
                },
                Confidential: {
                    type: ["null", "string"],
                },
                EligibleForTaxRelief: {
                    type: ["null", "string"],
                },
                GroupNumber: {
                    type: ["null", "string"],
                },
                OwnerName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingOwnerNameMapperBase",
                        },
                    ],
                },
                DBAName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingOwnerNameMapperBase",
                        },
                    ],
                },
                MailingAddress: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingAddressMapperBaseBase",
                        },
                    ],
                },
                OwnerPhoneNumbers: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingOwnerPhoneMapper",
                    },
                },
            },
        },
        ListingOwnerNameMapperBase: {
            type: "object",
        },
        ListingOwnerNamePersonMapper: {
            type: "object",
            additionalProperties: false,
            required: ["FirstName", "LastName"],
            properties: {
                FirstName: {
                    type: "string",
                    minLength: 1,
                },
                MiddleName: {
                    type: ["null", "string"],
                },
                LastName: {
                    type: "string",
                    minLength: 1,
                },
                Suffix: {
                    type: ["null", "string"],
                },
                Additional: {
                    type: ["null", "string"],
                },
            },
        },
        ListingOwnerNameBusinessMapper: {
            type: "object",
            additionalProperties: false,
            required: ["BusinessName", "BusinessType"],
            properties: {
                BusinessName: {
                    type: "string",
                    minLength: 1,
                },
                BusinessTypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                BusinessType: {
                    type: "string",
                    minLength: 1,
                },
            },
        },
        ListingAddressMapperBaseBase: {
            type: "object",
            required: ["AddressLine1"],
            properties: {
                InCareOf: {
                    type: ["null", "string"],
                    maxLength: 35,
                },
                AddressLine1: {
                    type: "string",
                    maxLength: 50,
                    minLength: 1,
                },
                AddressLine2: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                AddressLine3: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                City: {
                    type: ["null", "string"],
                    maxLength: 35,
                },
                StateNCPTSCode: {
                    type: ["null", "string"],
                },
                State: {
                    type: ["null", "string"],
                },
                PostalCode: {
                    type: ["null", "string"],
                },
                PostalCodeExtension: {
                    type: ["null", "string"],
                },
                CountyNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
            },
        },
        ListingOwnerPhoneMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                AreaCode: {
                    type: ["null", "string"],
                },
                Phone1: {
                    type: ["null", "string"],
                },
                Phone2: {
                    type: ["null", "string"],
                },
                Extension: {
                    type: ["null", "string"],
                },
                TypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                Type: {
                    type: ["null", "string"],
                },
                IsDefaultPhone: {
                    type: ["null", "string"],
                },
            },
        },
        OwnershipLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                OwnerOrders: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                OwnershipTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                BusinessTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                PhoneTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ValidValueLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                NcptsCode: {
                    type: ["null", "string"],
                },
                DisplayValue: {
                    type: ["null", "string"],
                },
            },
        },
        ListingSitusInformationMapperBase: {
            type: "object",
            properties: {
                IsDetailed: {
                    type: ["null", "string"],
                },
                SitusPk: {
                    type: ["null", "string"],
                },
                City: {
                    type: ["null", "string"],
                },
                CityNCPTSCode: {
                    type: ["null", "string"],
                },
                State: {
                    type: ["null", "string"],
                },
                StateNCPTSCode: {
                    type: ["null", "string"],
                },
                PostalCode: {
                    type: ["null", "string"],
                },
                PostalCodeExtension: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
                CountyNCPTSCode: {
                    type: ["null", "string"],
                },
                Address1: {
                    type: ["null", "string"],
                },
                Address2: {
                    type: ["null", "string"],
                },
                Address3: {
                    type: ["null", "string"],
                },
                TaxDistricts: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/SitusTaxDistrictMapper",
                    },
                },
                SitusValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/SitusLookup",
                        },
                    ],
                },
            },
        },
        SitusTaxDistrictMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                CountyNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
                TaxPercentage: {
                    type: ["null", "string"],
                },
            },
        },
        SitusLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Cities: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                TaxDistricts: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingAbstractInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ListingDate: {
                    type: ["null", "string"],
                },
                ExtensionRequestDate: {
                    type: ["null", "string"],
                },
                ExtensionDate: {
                    type: ["null", "string"],
                },
                Status: {
                    type: ["null", "string"],
                },
            },
        },
        ListingContactInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ContactName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ContactNameMapperBase",
                        },
                    ],
                },
                ContactAddress: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingAddressMapperBaseBase",
                        },
                    ],
                },
                ContactEmail: {
                    type: ["null", "string"],
                    format: "email",
                },
                ContactPhone: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingPhoneMapper",
                        },
                    ],
                },
                ContactValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ContactInfoLookup",
                        },
                    ],
                },
            },
        },
        ContactNameMapperBase: {
            type: "object",
            "x-abstract": true,
        },
        ContactNamePersonMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                FirstName: {
                    type: ["null", "string"],
                },
                MiddleName: {
                    type: ["null", "string"],
                },
                LastName: {
                    type: ["null", "string"],
                },
                Suffix: {
                    type: ["null", "string"],
                },
                Additional: {
                    type: ["null", "string"],
                },
            },
        },
        ContactNameBusinessMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                BusinessName: {
                    type: ["null", "string"],
                },
                BusinessTypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                BusinessType: {
                    type: ["null", "string"],
                },
            },
        },
        ListingPhoneMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                AreaCode: {
                    type: ["null", "string"],
                },
                Phone1: {
                    type: ["null", "string"],
                },
                Phone2: {
                    type: ["null", "string"],
                },
                Extension: {
                    type: ["null", "string"],
                },
                TypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                Type: {
                    type: ["null", "string"],
                },
            },
        },
        ContactInfoLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                PhoneTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                BusinessTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingBusinessInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                NAICSCode: {
                    type: ["null", "string"],
                },
                BusinessBeganDate: {
                    type: ["null", "string"],
                },
                BusinessYearEnd: {
                    type: ["null", "string"],
                },
                BusinessClassNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                BusinessClass: {
                    type: ["null", "string"],
                },
                BusinessInfoValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/BusinessInformationLookup",
                        },
                    ],
                },
            },
        },
        BusinessInformationLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                BusinessClasses: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingOutOfBusinessInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                OutOfBusinessDate: {
                    type: ["null", "string"],
                },
                OutOfBusinessReasonNCPTSCode: {
                    type: ["null", "string"],
                },
                OutOfBusinessReason: {
                    type: ["null", "string"],
                },
                OutOfBusinessInfoValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/OutOfBusinessInformationLookup",
                        },
                    ],
                },
            },
        },
        OutOfBusinessInformationLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                OutOfBusinessReasons: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingCountyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                CountyNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
            },
        },
        ListingPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ScheduleA: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingGroupPropertyInformationMapper",
                        },
                    ],
                },
                ScheduleB: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingVehicleProperty",
                        },
                    ],
                },
                ScheduleC: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingLeasedPropertyInformationMapper",
                    },
                },
                ScheduleE: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingFarmEquipmentPropertyMapper",
                    },
                },
                ScheduleEValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/FarmEquipmentLookup",
                        },
                    ],
                },
                Other: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingOtherPropertyInformationMapper",
                        },
                    ],
                },
            },
        },
        ListingGroupPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                Group1: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
                Group2: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingSummaryPropertyInformationMapper",
                    },
                },
                Group3: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
                Group4: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
                Group5: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
                Group6: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
                Group7: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingSummaryPropertyInformationMapper",
                    },
                },
                Group8: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingBusinessPropertyInformationMapper",
                    },
                },
            },
        },
        ListingBusinessPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                PropertyPK: {
                    type: ["null", "string"],
                },
                GroupDescription: {
                    type: ["null", "string"],
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                GroupNumber: {
                    type: ["null", "string"],
                },
                Schedule: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingGroupScheduleMapper",
                        },
                    ],
                },
                Years: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingPropertyYearInformationMapper",
                    },
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
            },
        },
        ListingGroupScheduleMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ScheduleName: {
                    type: ["null", "string"],
                },
                CategoryNumber: {
                    type: ["null", "string"],
                },
                CategoryDescription: {
                    type: ["null", "string"],
                },
            },
        },
        ListingPropertyYearInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                Year: {
                    type: ["null", "string"],
                },
                PriorYearCost: {
                    type: ["null", "string"],
                },
                Additions: {
                    type: ["null", "string"],
                },
                Deletions: {
                    type: ["null", "string"],
                },
                CurrentYearCost: {
                    type: ["null", "string"],
                },
            },
        },
        ListingSummaryPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                PropertyPK: {
                    type: ["null", "string"],
                },
                GroupDescription: {
                    type: ["null", "string"],
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                PriorYearValue: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
            },
        },
        ListingVehicleProperty: {
            type: "object",
            additionalProperties: false,
            properties: {
                UnregisteredVehicles: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingVehicle",
                    },
                },
                VehicleValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/VehicleLookup",
                        },
                    ],
                },
                Watercraft: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingVehicle",
                    },
                },
                ManufacturedHomes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingVehicle",
                    },
                },
                Aircraft: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingVehicle",
                    },
                },
                WatercraftValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/BusWatercraftLookup",
                        },
                    ],
                },
                ManufacturedHomeValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/BusMfdHomeLookup",
                        },
                    ],
                },
                AircraftValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/BusAircraftLookup",
                        },
                    ],
                },
            },
        },
        ListingVehicleBase: {
            type: "object",
            "x-abstract": true,
            properties: {
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
            },
        },
        ListingAircraftDetailed: {
            type: "object",
            additionalProperties: false,
            required: ["Year", "Make"],
            properties: {
                Year: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                Model: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                TailNumber: {
                    type: ["null", "string"],
                    maxLength: 20,
                },
                Airport: {
                    type: ["null", "string"],
                },
                HangarNumber: {
                    type: ["null", "string"],
                },
                HangarNumberExtension: {
                    type: ["null", "string"],
                },
            },
        },
        ListingAircraftSummary: {
            type: "object",
            additionalProperties: false,
            required: ["NumberOfProperties"],
            properties: {
                NumberOfProperties: {
                    type: "string",
                    maxLength: 5,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
            },
        },
        ListingManufacturedHomeDetailed: {
            type: "object",
            additionalProperties: false,
            required: ["Year", "Make", "Size"],
            properties: {
                Year: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                Size: {
                    $ref: "#/definitions/VehicleSize",
                },
                VIN: {
                    type: ["null", "string"],
                    maxLength: 20,
                },
                Park: {
                    type: ["null", "string"],
                },
                LotNumber: {
                    type: ["null", "string"],
                },
                LotNumberExtension: {
                    type: ["null", "string"],
                },
            },
        },
        VehicleSize: {
            type: "object",
            additionalProperties: false,
        },
        ListingManufacturedHomeSummary: {
            type: "object",
            additionalProperties: false,
            required: ["NumberOfProperties"],
            properties: {
                NumberOfProperties: {
                    type: "string",
                    maxLength: 5,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
            },
        },
        ListingWatercraftDetailed: {
            type: "object",
            additionalProperties: false,
            required: ["Year", "Make"],
            properties: {
                Year: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                Model: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                Size: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/VehicleSize",
                        },
                    ],
                },
                RegistrationNumber: {
                    type: ["null", "string"],
                    maxLength: 20,
                },
                EngineTypeNCPTSCode: {
                    type: ["null", "string"],
                },
                EngineType: {
                    type: ["null", "string"],
                },
                MotorInformation: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingMotor",
                    },
                },
                Marina: {
                    type: ["null", "string"],
                },
                SlipNumber: {
                    type: ["null", "string"],
                },
                SlipNumberExtension: {
                    type: ["null", "string"],
                },
            },
        },
        ListingMotor: {
            type: "object",
            additionalProperties: false,
            properties: {
                Make: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                HorsePower: {
                    type: ["null", "string"],
                    pattern: "(^\\d{0,8}).(\\d{0,2}$)",
                },
                Value: {
                    type: ["null", "string"],
                    maxLength: 10,
                },
            },
        },
        ListingWatercraftSummary: {
            type: "object",
            additionalProperties: false,
            required: ["NumberOfProperties"],
            properties: {
                NumberOfProperties: {
                    type: "string",
                    maxLength: 5,
                    minLength: 1,
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
            },
        },
        VehicleLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                VehicleSubtypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/VehicleSubtypeLookup",
                    },
                },
                BodyTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        VehicleSubtypeLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                VehicleSubtypeNCPTSCode: {
                    type: ["null", "string"],
                },
                VehicleSubtype: {
                    type: ["null", "string"],
                },
            },
        },
        BusWatercraftLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Marinas: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                EngineTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        BusMfdHomeLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Parks: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        BusAircraftLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Airports: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        ListingLeasedPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                AddressPK: {
                    type: ["null", "string"],
                },
                BusinessPartnerPK: {
                    type: ["null", "string"],
                },
                LeasedPropertyPK: {
                    type: ["null", "string"],
                },
                NamePK: {
                    type: ["null", "string"],
                },
                OwnerName: {
                    type: ["null", "string"],
                },
                OwnerAddress: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingAddressMapperBaseBase",
                        },
                    ],
                },
                Description: {
                    type: ["null", "string"],
                },
                DateOfLease: {
                    type: ["null", "string"],
                },
                AnnualRent: {
                    type: ["null", "string"],
                },
                LengthOfLease: {
                    type: ["null", "string"],
                },
                SerialNumber: {
                    type: ["null", "string"],
                },
                SellingPriceNew: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
            },
        },
        ListingFarmEquipmentPropertyMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                YearAcquired: {
                    type: ["null", "string"],
                },
                OriginalCost: {
                    type: ["null", "string"],
                },
                PropertyPK: {
                    type: ["null", "string"],
                },
                GroupDescription: {
                    type: ["null", "string"],
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                PriorYearValue: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: ["null", "string"],
                },
                Make: {
                    type: ["null", "string"],
                },
                Model: {
                    type: ["null", "string"],
                },
                FuelCode: {
                    type: ["null", "string"],
                },
                FuelCodeNCPTSCode: {
                    type: ["null", "string"],
                },
            },
        },
        FarmEquipmentLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                FuelCodes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingOtherPropertyInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                MISC: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingMiscPropertyMapper",
                    },
                },
                EXEMPT: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingSummaryPropertyInformationMapper",
                    },
                },
                INDEX: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingSummaryPropertyInformationMapper",
                    },
                },
            },
        },
        ListingMiscPropertyMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                PropertyPK: {
                    type: ["null", "string"],
                },
                GroupDescription: {
                    type: ["null", "string"],
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                PriorYearValue: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                OriginalCost: {
                    type: ["null", "string"],
                },
            },
        },
        ListingVehicle: {
            oneOf: [
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["Year", "Make"],
                    properties: {
                        Year: {
                            type: "string",
                            maxLength: 4,
                            minLength: 4,
                        },
                        Make: {
                            type: "string",
                            maxLength: 20,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                        Model: {
                            type: ["null", "string"],
                            maxLength: 50,
                        },
                        TailNumber: {
                            type: ["null", "string"],
                            maxLength: 20,
                        },
                        Airport: {
                            type: ["null", "string"],
                        },
                        HangarNumber: {
                            type: ["null", "string"],
                        },
                        HangarNumberExtension: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["NumberOfProperties"],
                    properties: {
                        NumberOfProperties: {
                            type: "string",
                            maxLength: 5,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["Year", "Make", "Size"],
                    properties: {
                        Year: {
                            type: "string",
                            maxLength: 4,
                            minLength: 4,
                        },
                        Make: {
                            type: "string",
                            maxLength: 20,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                        Size: {
                            $ref: "#/definitions/VehicleSize",
                        },
                        VIN: {
                            type: ["null", "string"],
                            maxLength: 20,
                        },
                        Park: {
                            type: ["null", "string"],
                        },
                        LotNumber: {
                            type: ["null", "string"],
                        },
                        LotNumberExtension: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["NumberOfProperties"],
                    properties: {
                        NumberOfProperties: {
                            type: "string",
                            maxLength: 5,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["Year", "Make"],
                    properties: {
                        Year: {
                            type: "string",
                            maxLength: 4,
                            minLength: 4,
                        },
                        Make: {
                            type: "string",
                            maxLength: 20,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                        Model: {
                            type: ["null", "string"],
                            maxLength: 50,
                        },
                        Size: {
                            oneOf: [
                                {
                                    type: "null",
                                },
                                {
                                    $ref: "#/definitions/VehicleSize",
                                },
                            ],
                        },
                        RegistrationNumber: {
                            type: ["null", "string"],
                            maxLength: 20,
                        },
                        EngineTypeNCPTSCode: {
                            type: ["null", "string"],
                        },
                        EngineType: {
                            type: ["null", "string"],
                        },
                        MotorInformation: {
                            type: ["array", "null"],
                            items: {
                                $ref: "#/definitions/ListingMotor",
                            },
                        },
                        Marina: {
                            type: ["null", "string"],
                        },
                        SlipNumber: {
                            type: ["null", "string"],
                        },
                        SlipNumberExtension: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["NumberOfProperties"],
                    properties: {
                        NumberOfProperties: {
                            type: "string",
                            maxLength: 5,
                            minLength: 1,
                        },
                        PropertyDescription: {
                            type: ["null", "string"],
                        },
                        PropertyPk: {
                            type: ["null", "string"],
                        },
                        YearAcquired: {
                            type: ["null", "string"],
                        },
                        AcquisitionCost: {
                            type: ["null", "string"],
                        },
                        VoidEffectiveDate: {
                            type: ["null", "string"],
                        },
                        AppraisedValue: {
                            type: ["null", "string"],
                        },
                    },
                },
            ],
        },
        ListingAddressMapper: {},
        ContactNameMapper: {
            oneOf: [
                {
                    type: "object",
                    additionalProperties: false,
                    properties: {
                        FirstName: {
                            type: ["null", "string"],
                        },
                        MiddleName: {
                            type: ["null", "string"],
                        },
                        LastName: {
                            type: ["null", "string"],
                        },
                        Suffix: {
                            type: ["null", "string"],
                        },
                        Additional: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    properties: {
                        BusinessName: {
                            type: ["null", "string"],
                        },
                        BusinessTypeNCPTSLookupCode: {
                            type: ["null", "string"],
                        },
                        BusinessType: {
                            type: ["null", "string"],
                        },
                    },
                },
            ],
        },
        ListingAddressMapperBase: {},
        ListingOwnerNameMapper: {
            oneOf: [
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["FirstName", "LastName"],
                    properties: {
                        FirstName: {
                            type: "string",
                            minLength: 1,
                        },
                        MiddleName: {
                            type: ["null", "string"],
                        },
                        LastName: {
                            type: "string",
                            minLength: 1,
                        },
                        Suffix: {
                            type: ["null", "string"],
                        },
                        Additional: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["BusinessName", "BusinessType"],
                    properties: {
                        BusinessName: {
                            type: "string",
                            minLength: 1,
                        },
                        BusinessTypeNCPTSLookupCode: {
                            type: ["null", "string"],
                        },
                        BusinessType: {
                            type: "string",
                            minLength: 1,
                        },
                    },
                },
            ],
        },
        ListingSitusInformationMapper: {},
    },
};
exports.default = bppSchema;
