import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormProvider, Metadata } from "@taxscribe/form";
import { CurrentSubmission, FormDataUnion } from "@taxscribe/gql-queries";

import { FormPeriod } from "../queries/returnTypes";
import ShowPDFOrForm from "../../../shared/ShowPDFOrForm";
import NCPTSDebugHelper from "../../../shared/NCPTSDebugHelper";

export interface SinglePageViewProps {
  formState: FormDataUnion;
  formPeriod: FormPeriod;
  metadata: Partial<CurrentSubmission> & Metadata;
}

export interface CreateSinglePageFormViewArgs {
  Provider: FormProvider,
  FormComponent: FC,
}

const DebugWrapper: FC<{
  includeNcptsDebug?: boolean;
}> = ({ includeNcptsDebug }) => {
  if (includeNcptsDebug) {
    return <NCPTSDebugHelper />;
  }

  return null;
};

const createSinglePageFormView = ({
  Provider,
  FormComponent,
}: CreateSinglePageFormViewArgs) => {
  const SinglePageFormView: FC<SinglePageViewProps> = ({
    metadata,
    formState,
    formPeriod,
  }) => {
    const data = formState as any;
    return (
      <ShowPDFOrForm formPeriod={formPeriod}>
        <Provider
          renderingApp="TSG"
          readOnly
          metadata={metadata}
          initialState={data}
        >
          <Container fluid>
            <Row>
              <Col sm={12}>
                <FormComponent />
              </Col>
            </Row>
          </Container>
          <DebugWrapper />
        </Provider>
      </ShowPDFOrForm>
    );
  };

  return SinglePageFormView;
};

export default createSinglePageFormView;
