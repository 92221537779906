import Ajv, { ErrorObject } from "ajv";
import { useMemo } from "react";
import schemas from "@taxscribe/ncpts-schemas";
import mapToJsonErrors from "./errorMapper";

type ValidSchemaKey = keyof typeof schemas;
export const currentSchemaVersion: ValidSchemaKey = "9.3.0";

const ajv = new Ajv({
  meta: false,
  allErrors: true,
  strict: false,
});
const currentSchemas = schemas[currentSchemaVersion];
const compiledSchemas: Record<string, any> = {
  "Listing": ajv.compile(currentSchemas.bpp),
  "IPP Listing": ajv.compile(currentSchemas.ipp),
};

export interface UseSchemaErrorsArgs {
  data: Record<string, any> | null | undefined;
  shortName: string;
}

export interface UseSchemaErrorsReturn {
  jsonErrors: Record<string, any> | null;
  rawErrors: ErrorObject[] | null;
  schemaVersion: ValidSchemaKey;
}

const useSchemaErrors = ({
  data,
  shortName,
}: UseSchemaErrorsArgs): UseSchemaErrorsReturn => {
  return useMemo(() => {
    const compiledSchema = compiledSchemas[shortName];
    if (!compiledSchema || !data) {
      return {
        jsonErrors: null,
        rawErrors: null,
        schemaVersion: currentSchemaVersion,
      };
    }

    const invalidData = {
      ...data?.Value,
    };
    const valid = compiledSchema(invalidData);

    if (valid) {
      return {
        jsonErrors: null,
        rawErrors: null,
        schemaVersion: currentSchemaVersion,
      };
    }
    const jsonErrors = mapToJsonErrors(
      compiledSchema.errors,
    );

    return {
      jsonErrors,
      rawErrors: compiledSchema.errors,
      schemaVersion: currentSchemaVersion,
    };
  }, [data, shortName]);
};

export default useSchemaErrors;
