"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const miscEntries = [
    [
        { key: "description", label: "Description" },
        { key: "appraisedValue", label: "Appraised Value" },
        { key: "priorYearValue", label: "Prior Year Value" },
    ],
    [
        { key: "cost", label: "Original Cost" },
        { key: "yearAcquired", label: "Year Acquired" },
    ],
];
exports.default = miscEntries;
