"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ippSchema = {
    // $schema: "http://json-schema.org/draft-04/schema#",
    title: "IndividualListingAbstractMapper",
    type: "object",
    additionalProperties: false,
    properties: {
        OwnershipInformation: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingOwnershipInformationMapper",
                },
            ],
        },
        SitusInformation: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingSitusInformationMapperBase",
                },
            ],
        },
        AbstractInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingAbstractInformationMapper",
                },
            ],
        },
        ContactInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/ListingContactInformationMapper",
                },
            ],
        },
        PropertyInfo: {
            oneOf: [
                {
                    type: "null",
                },
                {
                    $ref: "#/definitions/IndividualPropertyList",
                },
            ],
        },
    },
    definitions: {
        ListingOwnershipInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                Owners: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingOwnerMapper",
                    },
                },
                OwnerValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/OwnershipLookup",
                        },
                    ],
                },
            },
        },
        ListingOwnerMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                OwnerPk: {
                    type: ["null", "string"],
                },
                COACode: {
                    type: ["null", "string"],
                },
                OwnerOrder: {
                    type: ["null", "string"],
                },
                OwnerOrderNCPTSCode: {
                    type: ["null", "string"],
                },
                OwnershipType: {
                    type: ["null", "string"],
                },
                OwnershipTypeNCPTSCode: {
                    type: ["null", "string"],
                },
                PercentOwnership: {
                    type: ["null", "string"],
                },
                SendMail: {
                    type: ["null", "string"],
                },
                Confidential: {
                    type: ["null", "string"],
                },
                EligibleForTaxRelief: {
                    type: ["null", "string"],
                },
                GroupNumber: {
                    type: ["null", "string"],
                },
                OwnerName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingOwnerNameMapperBase",
                        },
                    ],
                },
                DBAName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingOwnerNameMapperBase",
                        },
                    ],
                },
                MailingAddress: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingAddressMapperBaseBase",
                        },
                    ],
                },
                OwnerPhoneNumbers: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingOwnerPhoneMapper",
                    },
                },
            },
        },
        ListingOwnerNameMapperBase: {
            type: "object",
        },
        ListingOwnerNamePersonMapper: {
            type: "object",
            additionalProperties: false,
            required: ["FirstName", "LastName"],
            properties: {
                FirstName: {
                    type: "string",
                    minLength: 1,
                },
                MiddleName: {
                    type: ["null", "string"],
                },
                LastName: {
                    type: "string",
                    minLength: 1,
                },
                Suffix: {
                    type: ["null", "string"],
                },
                Additional: {
                    type: ["null", "string"],
                },
            },
        },
        ListingOwnerNameBusinessMapper: {
            type: "object",
            additionalProperties: false,
            required: ["BusinessName", "BusinessType"],
            properties: {
                BusinessName: {
                    type: "string",
                    minLength: 1,
                },
                BusinessTypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                BusinessType: {
                    type: "string",
                    minLength: 1,
                },
            },
        },
        ListingAddressMapperBaseBase: {
            type: "object",
            required: ["AddressLine1"],
            properties: {
                InCareOf: {
                    type: ["null", "string"],
                    maxLength: 35,
                },
                AddressLine1: {
                    type: "string",
                    maxLength: 50,
                    minLength: 1,
                },
                AddressLine2: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                AddressLine3: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                City: {
                    type: ["null", "string"],
                    maxLength: 35,
                },
                StateNCPTSCode: {
                    type: ["null", "string"],
                },
                State: {
                    type: ["null", "string"],
                },
                PostalCode: {
                    type: ["null", "string"],
                },
                PostalCodeExtension: {
                    type: ["null", "string"],
                },
                CountyNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
            },
        },
        ListingOwnerPhoneMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                AreaCode: {
                    type: ["null", "string"],
                },
                Phone1: {
                    type: ["null", "string"],
                },
                Phone2: {
                    type: ["null", "string"],
                },
                Extension: {
                    type: ["null", "string"],
                },
                TypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                Type: {
                    type: ["null", "string"],
                },
                IsDefaultPhone: {
                    type: ["null", "string"],
                },
            },
        },
        OwnershipLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                OwnerOrders: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                OwnershipTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                BusinessTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                PhoneTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ValidValueLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                NcptsCode: {
                    type: ["null", "string"],
                },
                DisplayValue: {
                    type: ["null", "string"],
                },
            },
        },
        ListingSitusInformationMapperBase: {
            type: "object",
            properties: {
                IsDetailed: {
                    type: ["null", "string"],
                },
                SitusPk: {
                    type: ["null", "string"],
                },
                City: {
                    type: ["null", "string"],
                },
                CityNCPTSCode: {
                    type: ["null", "string"],
                },
                State: {
                    type: ["null", "string"],
                },
                StateNCPTSCode: {
                    type: ["null", "string"],
                },
                PostalCode: {
                    type: ["null", "string"],
                },
                PostalCodeExtension: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
                CountyNCPTSCode: {
                    type: ["null", "string"],
                },
                Address1: {
                    type: ["null", "string"],
                },
                Address2: {
                    type: ["null", "string"],
                },
                Address3: {
                    type: ["null", "string"],
                },
                TaxDistricts: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/SitusTaxDistrictMapper",
                    },
                },
                SitusValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/SitusLookup",
                        },
                    ],
                },
            },
        },
        SitusTaxDistrictMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                CountyNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                County: {
                    type: ["null", "string"],
                },
                TaxPercentage: {
                    type: ["null", "string"],
                },
            },
        },
        SitusLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Cities: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                TaxDistricts: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        ListingAbstractInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ListingDate: {
                    type: ["null", "string"],
                },
                ExtensionRequestDate: {
                    type: ["null", "string"],
                },
                ExtensionDate: {
                    type: ["null", "string"],
                },
                Status: {
                    type: ["null", "string"],
                },
            },
        },
        ListingContactInformationMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                ContactName: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ContactNameMapperBase",
                        },
                    ],
                },
                ContactAddress: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingAddressMapperBaseBase",
                        },
                    ],
                },
                ContactEmail: {
                    type: ["null", "string"],
                    format: "email",
                },
                ContactPhone: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ListingPhoneMapper",
                        },
                    ],
                },
                ContactValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/ContactInfoLookup",
                        },
                    ],
                },
            },
        },
        ContactNameMapperBase: {
            type: "object",
            "x-abstract": true,
        },
        ContactNamePersonMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                FirstName: {
                    type: ["null", "string"],
                },
                MiddleName: {
                    type: ["null", "string"],
                },
                LastName: {
                    type: ["null", "string"],
                },
                Suffix: {
                    type: ["null", "string"],
                },
                Additional: {
                    type: ["null", "string"],
                },
            },
        },
        ContactNameBusinessMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                BusinessName: {
                    type: ["null", "string"],
                },
                BusinessTypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                BusinessType: {
                    type: ["null", "string"],
                },
            },
        },
        ListingPhoneMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                AreaCode: {
                    type: ["null", "string"],
                },
                Phone1: {
                    type: ["null", "string"],
                },
                Phone2: {
                    type: ["null", "string"],
                },
                Extension: {
                    type: ["null", "string"],
                },
                TypeNCPTSLookupCode: {
                    type: ["null", "string"],
                },
                Type: {
                    type: ["null", "string"],
                },
            },
        },
        ContactInfoLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                States: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                Counties: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                PhoneTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
                BusinessTypes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ValidValueLookup",
                    },
                },
            },
        },
        IndividualPropertyList: {
            type: "object",
            additionalProperties: false,
            properties: {
                UnregisteredVehicles: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/IndUnregisteredVehicle",
                    },
                },
                PermanentPlateVehicles: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/IndPermanentVehicle",
                    },
                },
                VehicleValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/IndVehicleLookup",
                        },
                    ],
                },
                Watercraft: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/IndWatercraft",
                    },
                },
                WatercraftValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/IndWatercraftLookup",
                        },
                    ],
                },
                ManufacturedHomes: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/IndMfdHome",
                    },
                },
                ManufacturedHomeValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/IndMfdHomeLookup",
                        },
                    ],
                },
                Aircraft: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/IndAircraft",
                    },
                },
                AircraftValidValues: {
                    oneOf: [
                        {
                            type: "null",
                        },
                        {
                            $ref: "#/definitions/IndAircraftLookup",
                        },
                    ],
                },
                Misc: {
                    type: ["array", "null"],
                    items: {
                        $ref: "#/definitions/ListingMiscPropertyMapper",
                    },
                },
            },
        },
        IndUnregisteredVehicle: {
            type: "object",
            additionalProperties: false,
            required: ["ModelYear", "Make"],
            properties: {
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                Model: {
                    type: ["null", "string"],
                },
                Body: {
                    type: ["null", "string"],
                },
                VIN: {
                    type: ["null", "string"],
                },
                Length: {
                    type: ["null", "number"],
                    format: "decimal",
                },
                Width: {
                    type: ["null", "number"],
                    format: "decimal",
                },
            },
        },
        IndAircraft: {
            type: "object",
            additionalProperties: false,
            required: ["ModelYear", "Make"],
            properties: {
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                Model: {
                    type: ["null", "string"],
                    maxLength: 50,
                },
                TailNumber: {
                    type: ["null", "string"],
                    maxLength: 20,
                },
                Airport: {
                    type: ["null", "string"],
                },
                HangarNumber: {
                    type: ["null", "string"],
                },
                HangarNumberExtension: {
                    type: ["null", "string"],
                },
                Body: {
                    type: ["null", "string"],
                },
            },
        },
        IndWatercraft: {
            type: "object",
            additionalProperties: false,
            required: ["ModelYear", "Make"],
            properties: {
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                Model: {
                    type: ["null", "string"],
                },
                Feet: {
                    type: ["integer", "null"],
                    format: "int32",
                },
                Inches: {
                    type: ["integer", "null"],
                    format: "int32",
                },
                FuelCode: {
                    type: ["null", "string"],
                },
                ModelCode: {
                    type: ["null", "string"],
                },
                PropulsionCode: {
                    type: ["null", "string"],
                },
                PrimaryUseCode: {
                    type: ["null", "string"],
                },
                Marina: {
                    type: ["null", "string"],
                },
                SlipNumber: {
                    type: ["null", "string"],
                },
                SlipNumberExtension: {
                    type: ["null", "string"],
                },
                MotorMake1: {
                    type: ["null", "string"],
                },
                MotorMake2: {
                    type: ["null", "string"],
                },
                MotorHP1: {
                    type: ["null", "number"],
                    format: "decimal",
                },
                MotorHP2: {
                    type: ["null", "number"],
                    format: "decimal",
                },
                MotorSerialNum1: {
                    type: ["null", "string"],
                },
                MotorSerialNum2: {
                    type: ["null", "string"],
                },
                RegistrationNumber: {
                    type: ["null", "string"],
                    maxLength: 20,
                },
            },
        },
        IndPermanentVehicle: {
            type: "object",
            additionalProperties: false,
            required: ["ModelYear", "Make"],
            properties: {
                Model: {
                    type: ["null", "string"],
                },
                Body: {
                    type: ["null", "string"],
                },
                VIN: {
                    type: ["null", "string"],
                },
                Length: {
                    type: ["null", "number"],
                    format: "decimal",
                },
                Width: {
                    type: ["null", "number"],
                    format: "decimal",
                },
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                TitleNumber: {
                    type: ["null", "string"],
                },
                LicensePlateNumber: {
                    type: ["null", "string"],
                },
                RegistrationIssueDate: {
                    type: ["null", "string"],
                },
            },
        },
        IndMfdHome: {
            type: "object",
            additionalProperties: false,
            required: ["ModelYear", "Make"],
            properties: {
                PropertyPk: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                AcquisitionCost: {
                    type: ["null", "string"],
                },
                ModelYear: {
                    type: "string",
                    maxLength: 4,
                    minLength: 4,
                },
                Make: {
                    type: "string",
                    maxLength: 20,
                    minLength: 1,
                },
                Model: {
                    type: ["null", "string"],
                },
                ParcelID: {
                    type: ["null", "string"],
                },
                Body: {
                    type: ["null", "string"],
                },
                Length: {
                    type: ["integer", "null"],
                    format: "int32",
                },
                Width: {
                    type: ["integer", "null"],
                    format: "int32",
                },
                VIN: {
                    type: ["null", "string"],
                },
                PlateNumber: {
                    type: ["null", "string"],
                },
                Park: {
                    type: ["null", "string"],
                },
                LotNumber: {
                    type: ["null", "string"],
                },
                LotNumberExtension: {
                    type: ["null", "string"],
                },
                TitleNumber: {
                    type: ["null", "string"],
                },
                Occupant: {
                    type: ["null", "string"],
                },
                TitleName1: {
                    type: ["null", "string"],
                },
                TitleName2: {
                    type: ["null", "string"],
                },
                MovingPermitNumber: {
                    type: ["null", "string"],
                },
                MovingPermitIssueDate: {
                    type: ["null", "string"],
                },
                BuildingPermitNumber: {
                    type: ["null", "string"],
                },
                BuildingPermitIssueDate: {
                    type: ["null", "string"],
                },
            },
        },
        IndVehicleLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                BodyTypes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        IndWatercraftLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                ModelTypes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                PrimaryUseCodes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                FuelCodes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                PropulsionCodes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                Marinas: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        IndMfdHomeLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Parks: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                BodyTypes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        IndAircraftLookup: {
            type: "object",
            additionalProperties: false,
            properties: {
                Airports: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
                BodyTypes: {
                    type: ["array", "null"],
                    items: {
                        type: "string",
                    },
                },
            },
        },
        ListingMiscPropertyMapper: {
            type: "object",
            additionalProperties: false,
            properties: {
                PropertyPK: {
                    type: ["null", "string"],
                },
                GroupDescription: {
                    type: ["null", "string"],
                },
                PropertyDescription: {
                    type: ["null", "string"],
                },
                AppraisedValue: {
                    type: ["null", "string"],
                },
                PriorYearValue: {
                    type: ["null", "string"],
                },
                VoidEffectiveDate: {
                    type: ["null", "string"],
                },
                YearAcquired: {
                    type: ["null", "string"],
                },
                OriginalCost: {
                    type: ["null", "string"],
                },
            },
        },
        ListingAddressMapper: {},
        ContactNameMapper: {
            oneOf: [
                {
                    type: "object",
                    additionalProperties: false,
                    properties: {
                        FirstName: {
                            type: ["null", "string"],
                        },
                        MiddleName: {
                            type: ["null", "string"],
                        },
                        LastName: {
                            type: ["null", "string"],
                        },
                        Suffix: {
                            type: ["null", "string"],
                        },
                        Additional: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    properties: {
                        BusinessName: {
                            type: ["null", "string"],
                        },
                        BusinessTypeNCPTSLookupCode: {
                            type: ["null", "string"],
                        },
                        BusinessType: {
                            type: ["null", "string"],
                        },
                    },
                },
            ],
        },
        ListingAddressMapperBase: {},
        ListingOwnerNameMapper: {
            oneOf: [
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["FirstName", "LastName"],
                    properties: {
                        FirstName: {
                            type: "string",
                            minLength: 1,
                        },
                        MiddleName: {
                            type: ["null", "string"],
                        },
                        LastName: {
                            type: "string",
                            minLength: 1,
                        },
                        Suffix: {
                            type: ["null", "string"],
                        },
                        Additional: {
                            type: ["null", "string"],
                        },
                    },
                },
                {
                    type: "object",
                    additionalProperties: false,
                    required: ["BusinessName", "BusinessType"],
                    properties: {
                        BusinessName: {
                            type: "string",
                            minLength: 1,
                        },
                        BusinessTypeNCPTSLookupCode: {
                            type: ["null", "string"],
                        },
                        BusinessType: {
                            type: "string",
                            minLength: 1,
                        },
                    },
                },
            ],
        },
        ListingSitusInformationMapper: {},
    },
};
exports.default = ippSchema;
