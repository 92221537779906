import { gql } from "@apollo/client";
import { ALL_FORM_DATAS_FRAGMENT } from "@taxscribe/gql-queries";

export const GET_JURISDICTION_FORM_PDF = gql`
  query GetJurisdictionForm(
    $stateAbbr: String!
    $jurisdictionName: String!
    $jurisdictionType: String!
    $id: Int!
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      id
      name
      stateAbbr
      form(id: $id) {
        id
        ddbKey
        digitalOnly
        currentSubmission {
          id
          pdf {
            url
          }
        }
      }
    }
  }
`;

const GET_JURISDICTION_FORM = gql`
  ${ALL_FORM_DATAS_FRAGMENT}
  query GetJurisdictionForm(
    $stateAbbr: String!
    $jurisdictionName: String!
    $jurisdictionType: String!
    $id: Int!
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      id
      name
      stateAbbr
      form(id: $id) {
        id
        ...FormDataFragment
        ddbKey
        source
        ddbTable
        shortName
        createdAt
        updatedAt
        archived
        digitalOnly
        createdById
        formPeriodId
        formPeriod {
          id
          formTypeId
          filingDeadlineFormattedDay
          filingDeadlineTimestamp
          filingDeadline
          filingYear
        }
        businessCity
        businessName
        businessAddress
        ncptsData
        accountNumberPrimary
        accountNumberSecondary
        currentSubmission {
          id
          formId
          email
          pdfUrl
          createdAt
          updatedAt
          syncStatus
          contactName
          submittedAt
          status
          statusLastChangedById
          jurisdictionRespondedAt
        }
      }
    }
  }
`;

export default GET_JURISDICTION_FORM;
