import { set } from "lodash";
import { ErrorObject } from "ajv";

const sortErrors = (a: ErrorObject, b: ErrorObject) => {
  return a.instancePath.length - b.instancePath.length;
};

const trimRegex = /^\//;
const trimPath = (path: string) => {
  return path.replace(trimRegex, "").replace(/\//g, ".");
};

const mapToJsonErrors = (
  errors?: null | ErrorObject[],
) => {
  if (!Array.isArray(errors)) return {};
  return [...errors].sort(sortErrors).reduce((acc, error) => {
    return set(
      {
        ...acc,
      },
      trimPath(error.instancePath),
      error.message || "",
    );
  }, {});
};

export default mapToJsonErrors;
