import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import useGetSyncData from "../../../../hooks/useGetSyncData";
import useJurisdictionForm from "../../../../hooks/useJurisdictionForm";
import useJurisdictionContext from "../../../../hooks/useJurisdictionContext";
import { deepPurgeWithRegex } from "@taxscribe/utils";
import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import NCPTSDataOverview from "./NCPTSDataOverview";
import { Link, useLocation } from "react-router-dom";

const syncedFormTypes: Record<string, "bpp" | "ipp"> = {
  "Listing": "bpp",
  "IPP Listing": "ipp",
};

const removeValueTypes = (
  shouldHide: boolean,
  obj: Record<string, any> | undefined | null,
) => {
  if (!obj) return null;
  if (!shouldHide) {
    return deepPurgeWithRegex(
      /__typename|FormUUID/,
      obj,
    );
  }
  return deepPurgeWithRegex(
    /.*ValidValues$|__typename|FormUUID/,
    obj,
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
`;

const StyledCheckbox = styled(Form.Check)`
  margin-top: 1rem;
`;

const PreviewSync: FC = () => {
  const { pathname } = useLocation();
  const formPath = pathname.split("/").reverse().slice(1).reverse().join("/");
  const { jurisdiction } = useJurisdictionContext();
  const { data: formData } = useJurisdictionForm();
  const [hideAcceptedValues, setHideAcceptedValues] = useState(true);
  const [getSyncData, {
    data: syncData,
  }] = useGetSyncData();

  const form = formData?.jurisdiction?.form;
  useEffect(() => {
    if (jurisdiction.stateAbbr !== "NC") return;

    const ddbKey = form?.ddbKey;
    if (!jurisdiction.id || !ddbKey) return;

    const schemaKey = syncedFormTypes[form.shortName];
    if (!schemaKey) return;

    getSyncData({
      variables: {
        uuid: ddbKey,
        jurisdictionId: jurisdiction.id,
      },
    });
  }, [formData, jurisdiction, form, getSyncData]);

  const toggleAcceptedValues = useCallback(() => {
    setHideAcceptedValues(!hideAcceptedValues);
  }, [hideAcceptedValues, setHideAcceptedValues]);

  const syncPreview = useMemo(() => {
    return removeValueTypes(
      hideAcceptedValues,
      syncData?.jurisdiction?.form?.syncPreview?.preview,
    );
  }, [syncData, hideAcceptedValues]);

  const prePopData = useMemo(() => {
    return removeValueTypes(
      hideAcceptedValues,
      formData?.jurisdiction?.form?.ncptsData,
    );
  }, [formData, hideAcceptedValues]);

  if (!form || !syncData || !formData?.jurisdiction?.form?.ncptsData) {
    return null;
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <HeaderWrapper>
              <CheckboxWrapper>
                <h1>NCPTS Debug</h1>
                <StyledCheckbox
                  type="checkbox"
                  label="Hide Accepted Values"
                  checked={hideAcceptedValues}
                  onChange={toggleAcceptedValues}
                />
              </CheckboxWrapper>
              <Link to={formPath}>
                Return To Form
              </Link>
            </HeaderWrapper>
          </Col>
        </Row>
        <NCPTSDataOverview
          shortName={form?.shortName}
          syncData={syncPreview || null}
          ncptsData={prePopData || null}
        />
      </Container>
    </>
  );
};

export default PreviewSync;
