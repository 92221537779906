import * as styles from "react-syntax-highlighter/dist/esm/styles/prism";

export const possibleStyles = styles;

const addCustomStyles = (
  existingStyles: Record<string, any>,
  highlightPks: boolean,
) => {
  if (!highlightPks) return existingStyles;
  return {
    ...existingStyles,
    propertyPk: {
      backgroundColor: "yellow",
      color: "red",
    },
  };
};

export default addCustomStyles;
