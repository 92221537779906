import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";

// This is a carbon copy of Prism's JSON language definition
const baseJson = {
  property: {
    pattern: /(^|[^\\])"(?:\\.|[^\\"\r\n])*"(?=\s*:)/,
    lookbehind: true,
    greedy: true,
  },
  string: {
    pattern: /(^|[^\\])"(?:\\.|[^\\"\r\n])*"(?!\s*:)/,
    lookbehind: true,
    greedy: true,
  },
  comment: {
    pattern: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
    greedy: true,
  },
  number: /-?\b\d+(?:\.\d+)?(?:e[+-]?\d+)?\b/i,
  punctuation: /[{}[\],]/,
  operator: /:/,
  boolean: /\b(?:false|true)\b/,
  null: {
    pattern: /\bnull\b/,
    alias: "keyword",
  },
};

function customJson(Prism: any) {
  Prism.languages["custom-json"] = {
    propertyPk: {
      pattern: /(^|[^\\])"PropertyPK"|"PropertyPk"(?=\s*:)/,
      lookbehind: true,
      greedy: true,
    },
    ...baseJson,
  };
  Prism.languages.webmanifest = Prism.languages["custom-json"];
}

customJson.displayName = "custom-json";
SyntaxHighlighter.registerLanguage(
  "custom-json",
  customJson,
);

export default SyntaxHighlighter;
